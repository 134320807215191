import React from "react";
import { makeStyles } from "@mui/styles";
import { Container } from "@mui/material";
import Navbar from "../components/Navbar";
// import { SkeletonLoadImage } from "../components/SkeletonLoadImage";

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: "#121212",
    minHeight: "100vh",
    padding: 24,
    display: "flex",
    justifyContent: "center",
  },
  imageGrid: {
    display: "block",
    columnCount: 5,
    columnGap: "12px",
    maxWidth: "100%",
    margin: "0 auto",
  },
  galleryImage: {
    width: "100%",
    height: "auto",
    display: "block",
    marginBottom: "16px",
    borderRadius: "15px",
  },
  // Media queries can be omitted here if you prefer to keep them in a separate CSS file
  "@media (max-width: 1000px)": {
    imageGrid: {
      columnCount: 3,
    },
  },
  "@media (max-width: 800px)": {
    imageGrid: {
      columnCount: 2,
    },
  },
  "@media (max-width: 330px)": {
    imageGrid: {
      columnCount: 1,
    },
  },
}));

const Portfolio = () => {
  const classes = useStyles();

  // Create an array of image paths
  const imagePaths = Array.from(
    { length: 35 },
    (_, index) => `images/Gallery2/img${index + 1}.jpeg`
  );

  return (
    <>
      <Navbar />
      <div style={{ height: "60px", backgroundColor: "#121212" }}></div>
      <Container className={classes.root} maxWidth={false}>
        {/* Gallery Grid */}
        <div className={classes.imageGrid}>
          {imagePaths.map((path, index) => (
            <img
              key={index}
              src={path}
              alt={`car ${index + 1}`}
              className={classes.galleryImage}
            />
          ))}
        </div>
      </Container>
    </>
  );
};

export default Portfolio;
