import React from "react";
import { Typography, Rating } from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CarRepairIcon from "@mui/icons-material/CarRepair";
import SentimentVerySatisfiedIcon from "@mui/icons-material/SentimentVerySatisfied";

const Bubble = () => {
  const InnerBubble = ({ children }: any) => {
    return (
      <div
        style={{
          backgroundColor: "#1e1e1e",
          flexGrow: 1,
          flexBasis: "250px", // Start with 250px basis
          height: "110px",
          borderRadius: "20px",
          maxWidth: "100%", // Ensure it doesn't exceed the container width
        }}
      >
        <div style={{ padding: "20px" }}>{children}</div>
      </div>
    );
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        gap: "10px",
        maxWidth: "100%",
        marginTop: "30px",
        padding: "0 20px",
      }}
    >
      <InnerBubble>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="body1" color="white">
            Customer satisfaction
          </Typography>
          <SentimentVerySatisfiedIcon color="primary" />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            marginTop: "5px",
            marginBottom: "2px",
          }}
        >
          <Rating name="read-only" value={5} readOnly />
        </div>
        <Typography variant="caption" color="whitesmoke">
          ~ (Source:{" "}
          <a
            href="https://maps.app.goo.gl/6nVpmUMNpP1pG9Ln6"
            style={{ color: "whitesmoke" }}
          >
            <i>Google Reviews</i>
          </a>
          )
        </Typography>
      </InnerBubble>
      <InnerBubble>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="body1" color="white">
            Hours in the field
          </Typography>
          <AccessTimeIcon color="secondary" />
        </div>
        <Typography variant="h4" color="white">
          3000+
        </Typography>
      </InnerBubble>
      <InnerBubble>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="body1" color="white">
            Luxury Cars Detailed
          </Typography>
          <CarRepairIcon color="success" />
        </div>
        <Typography variant="h4" color="white">
          200+
        </Typography>
      </InnerBubble>
    </div>
  );
};

export default Bubble;
