import { makeStyles } from "@mui/styles";
// import image3 from '../../assets/images/image6.jpeg'

export default makeStyles(() => ({
  "@font-face": {
    fontFamily: "Maharlika",
    src: "url(../../assets/fonts/Maharlika.ttf)",
  },
  byName: {
    fontFamily: "Maharlika",
    fontSize: "16pt",
  },
}));
