import { Box } from "@mui/material";
import React from "react";
import ReviewCard from "../ReviewCard/ReviewCard";
import useStyles from "./styles";
// import { Carousel } from 'react-responsive-carousel';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Reviews.css";

const Reviews: React.FC = () => {
  const classes = useStyles();
  let reviews = [
    {
      content: `The best detailer in Sydney hands down. On time. Great service. Does not miss a spot on your car. If you want the service you imagine. These guys are the ones to make it happen.`,
      reviewer: "Edwin Rezania",
    },
    {
      content: `Easy to deal with, prompt in response and fair/reasonable with costs.`,
      reviewer: "Luke Walton",
    },
    {
      content: `Nice guy great service and attention to detail and very trustworthy I highly recommend`,
      reviewer: "GAP Finance",
    },
  ];

  const settings = {
    // dots: true,
    arrows: false,
  };

  return (
    <Box className={classes.reviewsContainer}>
      <Box className={classes.carouselContainer}>
        <Slider {...settings} autoplay={true} useTransform={false}>
          {reviews.map((review, idx) => (
            <ReviewCard content={review.content} reviewer={review.reviewer} />
          ))}
        </Slider>
      </Box>
    </Box>
  );
};

export default Reviews;
