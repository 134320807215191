import React from "react";
import Box from "@mui/material/Box";
import Slider from "react-slick";
import { useMediaQuery } from "@mui/material";
import "./HeroSection.css";

const HeroSection: React.FC = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
  };

  const isMobile = useMediaQuery("(max-width:600px)");

  return (
    <Box style={{ position: "relative" }}>
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          color: "white",
          fontSize: "42px",
          zIndex: "1",
          textAlign: "center",
          fontWeight: "bold",
          fontFamily: "sans-serif",
          backgroundColor: "rgba(0, 0, 0, 0.3)",
          height: "calc(100%)",
          width: "100%",
          pointerEvents: "none",
        }}
      >
        <div
          style={{
            position: "relative",
            height: "100%",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "90%",
            }}
          ></div>
        </div>
      </div>

      <>
        {isMobile && (
          <Slider
            {...settings}
            autoplay={true}
            pauseOnHover={false}
            useTransform={false}
          >
            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-around",
                  alignContent: "space-around",
                  width: "100%",
                  backgroundColor: "#121212",
                }}
              >
                <img
                  alt="car"
                  src="images/Home/IMG1.jpeg"
                  style={{
                    aspectRatio: "1",
                    maxWidth: "100%",
                    maxHeight: "100vh",
                  }}
                />
              </div>
            </div>
            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-around",
                  alignContent: "space-around",
                  width: "100%",
                  backgroundColor: "#121212",
                }}
              >
                <img
                  alt="car"
                  src="images/Home/IMG2.jpeg"
                  style={{
                    aspectRatio: "1",
                    maxWidth: "100%",
                    maxHeight: "100vh",
                  }}
                />
              </div>
            </div>
            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-around",
                  alignContent: "space-around",
                  width: "100%",
                  backgroundColor: "#121212",
                }}
              >
                <img
                  alt="car"
                  src="images/Home/IMG3.jpeg"
                  style={{
                    maxWidth: "100%",
                  }}
                />
              </div>
            </div>
          </Slider>
        )}
        {!isMobile && (
          <Slider
            {...settings}
            autoplay={true}
            pauseOnHover={false}
            useTransform={false}
            adaptiveHeight
          >
            <div style={{ height: "600px" }}>
              <img
                alt="car"
                src="images/Home/Desktop/IMG1.jpeg"
                style={{
                  maxHeight: "600px",
                  width: "100%",
                  objectFit: "cover",
                }}
              />
            </div>
            <div style={{ height: "600px" }}>
              <img
                alt="car"
                src="images/Home/Desktop/IMG2.jpeg"
                style={{
                  maxHeight: "600px",
                  width: "100%",
                  objectFit: "cover",
                }}
              />
            </div>
            <div style={{ height: "600px" }}>
              <img
                alt="car"
                src="images/Home/Desktop/IMG3.jpeg"
                style={{
                  maxHeight: "600px",
                  width: "100%",
                  objectFit: "cover",
                }}
              />
            </div>
          </Slider>
        )}
      </>
    </Box>
  );
};

export default HeroSection;
