import React, { useEffect } from "react";
import Navbar from "../components/Navbar";
import HeroSection from "../components/HeroSection";
import About from "../components/About/About";
import PricingComponent from "../components/Services";
import Reviews from "../components/Reviews/Reviews";
import { Divider } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

function Home() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { targetId } = state || {};

  useEffect(() => {
    const el = document.getElementById(targetId);
    if (el) {
      const offset = 80;
      const elementPosition = el.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - offset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });

      navigate("/");
    }
  }, [targetId, navigate]);

  return (
    <div style={{ backgroundColor: "#121212", color: "white" }}>
      <Navbar />
      <HeroSection />
      <About />
      <Divider sx={{ backgroundColor: "rgb(30, 30, 30)" }} />
      <PricingComponent />
      <Reviews />
    </div>
  );
}

export default Home;
