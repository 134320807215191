import * as React from "react";
import Card from "@mui/material/Card";
// import CardHeader from '@mui/material/CardHeader';
import CardContent from "@mui/material/CardContent";
// import Avatar from '@mui/material/Avatar';
import Typography from "@mui/material/Typography";
// import { blue } from '@mui/material/colors';
import useStyles from "./styles";
import { Rating } from "@mui/material";
import { Box } from "@mui/system";
// import popularImage from '../../assets/images/popularImage.jpeg'
// import { Carousel } from 'react-responsive-carousel';

type Props = {
  content: string;
  reviewer: string;
};

const ReviewCard: React.FC<Props> = ({ content, reviewer }) => {
  const classes = useStyles();

  return (
    <Box>
      <Card>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            marginTop: "30px",
          }}
        >
          <Rating
            name="half-rating-read"
            defaultValue={5}
            readOnly
            color="black"
          />
        </Box>

        <CardContent>
          <Typography
            variant="body2"
            sx={{
              marginLeft: "0px",
              fontStyle: "italic",
              minHeight: "100px",
              "@media (max-width:600px)": {},
              textAlign: "center",
            }}
          >
            {content}
          </Typography>
          <Box
            sx={{
              marginTop: "20px",
              textAlign: "center",
            }}
            className={classes.byName}
          >
            - {reviewer}
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default ReviewCard;
