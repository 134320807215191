import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import useStyles from "./styles";
import Bubble from "../Bubble";

const About: React.FC = () => {
  const classes = useStyles();

  return (
    <Box>
      <Box className={classes.textAreaCont}>
        <Box className={classes.textArea}>
          <Typography variant="h4" mt="15px" fontWeight={"400"} align="center">
            About us
          </Typography>

          {/* <Divider variant='middle' className={classes.divider} /> */}
          <Typography
            variant="body1"
            textAlign="center"
            fontFamily={'"Century Gothic" sans-serif'}
            marginTop={2}
          >
            We are a{" "}
            <b style={{ color: "#d4af37" }}>mobile detailing service</b> that
            brings the expertise to your doorstep. Our commitment is to treat
            every client's vehicle with the same care and precision as if it
            were our own, ensuring that all services are executed with{" "}
            <b style={{ color: "#d4af37" }}>exceptional quality</b>. We aspire
            to deliver an unparalleled experience for our clients, and should
            you have any questions or inquiries, please don't hesitate to reach
            out to us.
          </Typography>
          <div style={{ width: "100VW", maxWidth: "1000px" }}>
            <Bubble />
          </div>
        </Box>
      </Box>
    </Box>
  );
};

export default About;
