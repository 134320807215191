import React, { useState } from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  ThemeProvider,
  createTheme,
  Alert,
} from "@mui/material";
import Navbar from "../components/Navbar";
import emailjs from '@emailjs/browser';
import { useSearchParams } from "react-router-dom";

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    background: {
      default: '#121212',
      paper: '#121212',
    },
    text: {
      primary: '#ffffff',
      secondary: '#aaaaaa',
    },
    primary: {
      main: '#90caf9',
    },
    secondary: {
      main: '#f48fb1',
    },
  },
});

function ContactUs() {
  const [params] = useSearchParams()
  const service = params.get('service')

  console.log(service)
  const serviceMap = {
    maintenance: "Maintenance Detail",
    mini: "Mini Detail",
    ultimate: "Ultimate Detail",
    showroom: "Showroom Detail",
  };
  

  const [formState, setFormState] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    selectedService: service && service in serviceMap ? serviceMap[service] : "No service",
    carDetails: "",
    message: "",
  });
  

  const [errors, setErrors] = useState({});
  const [alertMessage, setAlertMessage] = useState(null);
  const [disabled, setDisabled] = useState(false)

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormState({
      ...formState,
      [name]: value,
    });
    setErrors({})
  };

  const validate = () => {
    const newErrors = {};
    if (!formState.name) newErrors.name = "Name is required";
    if (!formState.email) {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formState.email)) {
      newErrors.email = "Email address is invalid";
    }
    if (!formState.phoneNumber)
      newErrors.phoneNumber = "Phone number is required";
    if (!formState.message) newErrors.message = "Message is required";
    return newErrors;
  };


  const handleSubmit = async (event) => {
    event.preventDefault();
    setDisabled(true)

    const formErrors = validate();

    let hasTouchScreen = false;

    if ("maxTouchPoints" in navigator) {
        hasTouchScreen = navigator.maxTouchPoints > 0;
    } else if ("msMaxTouchPoints" in navigator) {
        hasTouchScreen = navigator.msMaxTouchPoints > 0;
    } else {
      let mQ = window.matchMedia && matchMedia("(pointer:coarse)");
      if (mQ && mQ.media === "(pointer:coarse)") {
          hasTouchScreen = !!mQ.matches;
      } else if ('orientation' in window) {
          hasTouchScreen = true; // deprecated, but good fallback
      } else {
          // Only as a last resort, fall back to user agent sniffing
          var UA = navigator.userAgent;
          hasTouchScreen = (
              /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) ||
              /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA)
          );
      }
    }
    
  
    if (Object.keys(formErrors).length === 0) {
      try {
        await emailjs.send("sydneyautodetailer", "contact_us", {
        name: formState.name,
        from_email: formState.email,
        from_phone: formState.phoneNumber,
        service: formState.selectedService,
        vehicle: formState.carDetails,
        message: formState.message,
        from_device: hasTouchScreen ? 'mobile' : 'desktop',
      }, 'zZBGHO5PSe6BD-qLv')

      setAlertMessage({ type: 'success', message: "Your message has been received 🎉 We'll get back to you in less than 24 hours." });

      } catch {
        setAlertMessage({ type: 'error', message: "An error occurred in sending an email. Please email us directly at info@sydneyautodetailer.com.au" });
      }
    } else {
      setErrors(formErrors);
      setDisabled(false)
    }
  };

  return (
    <ThemeProvider theme={darkTheme}>

    <div style={{  backgroundColor: '#121212', minHeight: '100vh', position: 'relative'}}>
      <div style={{ maxHeight: '200px', position: 'absolute', width: '100%', marginTop: '65px' }}>
      <img src="images/Contact/IMG2.jpeg" style={{
        maxHeight: "200px",
        width: "100%",
        objectFit: "cover",
      }} alt="car"/>
      </div>

      <div style={{ height: "60px", backgroundColor: "#121212" }}></div>
      <Navbar />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: '0 20px',
            paddingTop: '20px', 
            paddingBottom: '40px',
            backgroundColor: '#121212',
            color: 'white'
          }}
        >
          <Typography
            variant="h2"
            align="center"
            sx={{ color: "whitesmoke", zIndex: 900, marginTop: '40px', fontFamily: 'serif', fontWeight: 'bold' }}
            gutterBottom
          >
            Contact us
          </Typography>
          <Box
            component="form"
            sx={{ width: "100%", maxWidth: "500px", mt: '90px' }}
            onSubmit={handleSubmit}
          >
          {alertMessage && (
            <Alert
              severity={alertMessage.type === 'success' ? 'success' : 'error'}
              sx={{ marginBottom: '10px'}}
            >
              {alertMessage.message}
            </Alert>
          )}
            <TextField
              fullWidth
              label="Name"
              name="name"
              margin="normal"
              variant="outlined"
              required
              value={formState.name}
              onChange={handleChange}
              error={!!errors.name}
              helperText={errors.name}
              disabled={disabled}
            />

            <TextField
              fullWidth
              label="Email"
              name="email"
              margin="normal"
              variant="outlined"
              required
              value={formState.email}
              onChange={handleChange}
              error={!!errors.email}
              helperText={errors.email}
              disabled={disabled}
            />

            <TextField
              fullWidth
              label="Phone Number"
              name="phoneNumber"
              margin="normal"
              variant="outlined"
              required
              value={formState.phoneNumber}
              onChange={handleChange}
              error={!!errors.phoneNumber}
              disabled={disabled}
              helperText={errors.phoneNumber}
            />

            <FormControl fullWidth margin="normal" variant="outlined">
              <InputLabel>Service (optional)</InputLabel>
              <Select
                name="selectedService"
                value={formState.selectedService}
                onChange={handleChange}
                label="Service (optional)"
                disabled={disabled}
              >
                <MenuItem value="No service">N/A</MenuItem>
                <MenuItem value="Maintenance Detail">Maintenance Detail</MenuItem>
                <MenuItem value="Mini Detail">Mini Detail</MenuItem>
                <MenuItem value="Ultimate Detail">Ultimate Detail</MenuItem>
                <MenuItem value="Showroom Detail">Showroom Detail</MenuItem>

              </Select>
            </FormControl>

            <TextField
              fullWidth
              label="Vehicle Make & Model"
              name="carDetails"
              margin="normal"
              variant="outlined"
              value={formState.carDetails}
              onChange={handleChange}
              error={!!errors.carDetails}
              disabled={disabled}
              helperText={errors.carDetails}
            />

            <TextField
              fullWidth
              label="Message"
              name="message"
              margin="normal"
              variant="outlined"
              multiline
              rows={4}
              required
              value={formState.message}
              onChange={handleChange}
              disabled={disabled}
              error={!!errors.message}
              helperText={errors.message}
            />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              disabled={disabled}
              color="primary"
              sx={{
                mt: 2,
                backgroundColor: "#262626",
                "&:hover": {
                  backgroundColor: "#141414",
                },
                color: 'white'
              }}
            >
              Submit
            </Button>
          </Box>
        </Box>
      </div>
    </ThemeProvider>
  );
}

export default ContactUs;
