// Navbar.tsx
import React, { useEffect, useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Hidden,
  Link,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate } from "react-router-dom";

const Navbar: React.FC = () => {
  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const toggleDrawer = (open: boolean) => {
    setDrawerOpen(open);
  };

  const menuItems = [
    { name: "Home", link: "/", options: {} },
    {
      name: "Services",
      link: "/",
      options: { state: { targetId: "services" } },
    },
    { name: "Portfolio", link: "/portfolio", options: {} },
    { name: "Contact us", link: "/contact", options: {} },
  ];

  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const backgroundColor =
    scrollPosition === 0 ? "rgba(0, 0, 0, 0)" : "rgba(0, 0, 0, 0.9)";

  const navigateTo = useNavigate();

  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          background: backgroundColor,
          padding: "0px 80px",
          zIndex: 999,
          transition: "background 0.6s ease-in-out",
        }}
      >
        <Toolbar>
          <Hidden mdUp>
            {/* Display menu icon on the left for smaller screens */}
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => toggleDrawer(true)}
              sx={{ position: "fixed", marginLeft: "-50px" }}
            >
              <MenuIcon />
            </IconButton>
          </Hidden>

          {/* Logo in the center for smaller screens */}
          <Hidden mdUp>
            <div style={{ flex: 1, textAlign: "center" }}>
              <img
                src="/logo.webp"
                alt="Logo"
                style={{ height: "50px", cursor: "pointer" }}
                onClick={() => {
                  navigateTo("/");
                }}
              />
            </div>
          </Hidden>

          {/* Logo on the left and links on the right for larger screens */}
          <Hidden mdDown>
            <img
              src="/logo.webp"
              alt="Logo"
              style={{ height: "50px", cursor: "pointer" }}
              onClick={() => {
                navigateTo("/");
              }}
            />

            <nav
              style={{ flex: 1, display: "flex", justifyContent: "flex-end" }}
            >
              {menuItems.map((menuItem) => (
                <Typography
                  key={menuItem.name}
                  tabIndex={0}
                  component={Link}
                  // to={`/${text.toLowerCase()}`}  // Provide a meaningful href
                  sx={{
                    marginLeft: 5,
                    color: "white",
                    textDecoration: `${"none"}`, // Update text decoration
                    textUnderlineOffset: "12px",
                    fontFamily: "sans-serif",
                    fontWeight: 500,
                    cursor: "pointer",
                    fontSize: "20px",
                    paddingBottom: "4px",
                  }}
                  onClick={() => {
                    navigateTo(menuItem.link, menuItem.options);
                  }}
                >
                  {menuItem.name}
                </Typography>
              ))}
            </nav>
          </Hidden>
        </Toolbar>
      </AppBar>

      <Drawer
        anchor="left"
        open={drawerOpen}
        onClose={() => toggleDrawer(false)}
      >
        <List
          style={{
            backgroundColor: "#121212",
            height: "100%",
            color: "white",
          }}
        >
          <div style={{ flex: 1, textAlign: "center" }}>
            <img
              src="/logo.webp"
              alt="Logo"
              style={{ height: "40px", marginBottom: "10px" }}
            />
          </div>
          {menuItems.map((menuItem) => (
            <ListItem
              button
              key={menuItem.name}
              onClick={() => {
                navigateTo(menuItem.link, menuItem.options);
                setDrawerOpen(false);
              }}
            >
              <ListItemText
                style={{ textAlign: "center" }}
                primary={menuItem.name}
              />
            </ListItem>
          ))}
        </List>
      </Drawer>
    </>
  );
};

export default Navbar;
