import { makeStyles } from "@mui/styles";

export default makeStyles(() => ({
  divider: {
    width: "145px",
  },
  textAreaCont: {
    marginTop: "10px",
    marginBottom: "30px",
    padding: "0 20px",
    display: "flex",
  },
  textArea: {
    maxWidth: "600px",
    width: "90%",
    margin: "0 auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  text: {
    fontFamily: "sans-serif",
    textAlign: "center",
    fontSize: "16pt",
  },
}));
