import { makeStyles } from "@mui/styles";
// import image3 from "../../assets/images/image6.jpeg";
// import image from "../../../public/reviews-bg.jpeg";

export default makeStyles(() => ({
  reviewsContainer: {
    minHeight: "calc(100vh)",
    backgroundImage: `url(/r2.jpeg)`,
    display: "flex",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  carouselContainer: {
    width: "500px",
  },
  "@media (max-width:600px)": {
    carouselContainer: {
      width: "330px",
    },
  },
  "@media (min-width:768px)": {
    reviewsContainer: {
      backgroundImage: `url(/r1.jpeg)`,
    },
  },
}));
